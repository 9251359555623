import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

const MessagingPage = lazy(() => import('./pages/MessagingPage'));
const FilePage = lazy(() => import('./pages/FilePage'));

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<></>}>
				<Switch>
					<Route path="/file" exact strict>
						<FilePage />
					</Route>
					<Route path="/messaging" exact strict>
						<MessagingPage />
					</Route>
					<Route path="/">
						<Redirect to="/file" />
					</Route>
				</Switch>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
